document.addEventListener('DOMContentLoaded', () => {
  const defaultPublicPath = '/static/js/';

  // Try to find the `public-path` meta tag. If it exists, the value attribute contains the server-generated
  // path to the assets, where webpack can fetch chunks generated by its build.
  const metaPublicPath = document.querySelector('meta[name="public-path"]');

  if (metaPublicPath) {
    __webpack_public_path__ = `//${metaPublicPath.getAttribute(
      'value',
    )}${path}`;
  } else {
    __webpack_public_path__ = defaultPublicPath;
  }
});
